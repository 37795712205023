// src/components/AttractionRow.js

import React from 'react';
import './AttractionRow.css';

function AttractionRow({ attraction, isFavorite, toggleFavorite, isRidden, toggleRidden }) {
  const formattedTime = (timeString) => {
    if (!timeString) return null;
    const date = new Date(timeString);
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit' });
  };

  const renderReturnTime = () => {
    // Handle Paid Return Time
    if (attraction.paidReturnState) {
      if (attraction.paidReturnState.toUpperCase() === 'AVAILABLE' && attraction.paidReturnStart) {
        return (
          <p>
            Single Pass: {formattedTime(attraction.paidReturnStart)}{' '}
            {attraction.singlePassPrice && (
              <span className="price">(${Math.round(attraction.singlePassPrice)})</span>
            )}
          </p>
        );
      } else if (attraction.paidReturnState.toUpperCase() === 'FINISHED') {
        return <p className="sold-out">Single Pass: Sold Out</p>;
      }
    }
    // Handle Regular Return Time
    if (attraction.returnState) {
      if (attraction.returnState.toUpperCase() === 'AVAILABLE' && attraction.returnStart) {
        return <p>Lightning Lane: {formattedTime(attraction.returnStart)}</p>;
      } else if (attraction.returnState.toUpperCase() === 'FINISHED') {
        return <p className="sold-out">Lightning Lane: Sold Out</p>;
      } else if (attraction.returnState.toUpperCase() === 'UNAVAILABLE') {
        return <p>Lightning Lane Unavailable</p>;
      }
    }
    return null;
  };

  return (
    <div
      className={`attraction-row ${attraction.status.toLowerCase() === 'down' ? 'down' : ''} ${
        isRidden ? 'ridden' : ''
      }`}
    >
      <div className="attraction-info">
        <h3>{attraction.name}</h3>
        {/* Removed the boarding group display here */}
        {renderReturnTime()}
      </div>
      <div className="attraction-wait-time">
        {attraction.waitTime !== null && attraction.waitTime !== undefined ? (
          <strong>{attraction.waitTime} min</strong>
        ) : attraction.boardingGroup ? (
          <strong>Group {attraction.boardingGroup}</strong>
        ) : (
          <span>N/A</span>
        )}
        <div className="icons">
          <span className="favorite-icon" onClick={() => toggleFavorite(attraction)}>
            {isFavorite ? '⭐' : '☆'}
          </span>
          <label className="ridden-checkbox">
            <input
              type="checkbox"
              checked={isRidden}
              onChange={(e) => {
                e.stopPropagation();
                toggleRidden(attraction);
              }}
            />
            Rode
          </label>
        </div>
      </div>
    </div>
  );
}

export default AttractionRow;