// src/App.js

import React, { useState, useEffect } from 'react';
import apiService from './apiService';
import AttractionRow from './components/AttractionRow';
import './App.css';

function App() {
  const [selectedParkId, setSelectedParkId] = useState('75ea578a-adc8-4116-a54d-dccb60765ef9'); // Magic Kingdom
  const [attractions, setAttractions] = useState([]);
  const [favorites, setFavorites] = useState([]);
  const [riddenAttractions, setRiddenAttractions] = useState([]);
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  // Dark mode state
  const [darkMode, setDarkMode] = useState(false);

  // State variables for filter and sort
  const [filterType, setFilterType] = useState('All');
  const [sortOrder, setSortOrder] = useState('asc'); // 'asc' or 'desc'

  // State variable for today's operating hours
  const [todayOperatingHours, setTodayOperatingHours] = useState([]);
  const [parkTimezone, setParkTimezone] = useState('');

  useEffect(() => {
    loadFavorites();
    loadRiddenAttractions();
    fetchAttractions();
    fetchTodayOperatingHours(); // Fetch today's operating hours
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParkId]);

  // Detect system color scheme preference
  useEffect(() => {
    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
    setDarkMode(prefersDark);
  }, []);

  // Apply dark or light mode class to body element
  useEffect(() => {
    const className = darkMode ? 'dark-mode' : 'light-mode';
    document.body.classList.add(className);
    document.body.classList.remove(darkMode ? 'light-mode' : 'dark-mode');
  }, [darkMode]);

  const parkOptions = [
    { id: '75ea578a-adc8-4116-a54d-dccb60765ef9', name: '🏰' }, // Magic Kingdom
    { id: '47f90d2c-e191-4239-a466-5892ef59a88b', name: '🌐' }, // EPCOT
    { id: '288747d1-8b4f-4a64-867e-ea7c9b27bad8', name: '🎬' }, // Hollywood Studios
    { id: '1c84a229-8862-4648-9c71-378ddd2c7693', name: '🌳' }, // Animal Kingdom
  ];

  const fetchAttractions = () => {
    setIsLoading(true);
    setErrorMessage(null);
    apiService
      .fetchEntityLiveData(selectedParkId)
      .then((response) => {
        const data = response.data;
        const fetchedAttractions = data.liveData
          .filter(
            (entity) =>
              (entity.entityType.toLowerCase() === 'attraction' ||
                entity.entityType.toLowerCase() === 'show') &&
              // Exclude attractions with status 'REFURBISHMENT' or 'CLOSED'
              !['refurbishment', 'closed'].includes(entity.status.toLowerCase())
          )
          .map((entity) => {
            // Extract necessary data
            const waitTime = entity.queue?.STANDBY?.waitTime;
            const status = entity.status;
            const type = entity.entityType.toLowerCase();
            const boardingGroupData = entity.queue?.BOARDING_GROUP;
            let boardingGroup = null;

            if (boardingGroupData) {
              const currentGroupStart = boardingGroupData.currentGroupStart;
              const currentGroupEnd = boardingGroupData.currentGroupEnd;

              if (currentGroupStart && currentGroupEnd) {
                boardingGroup = `${currentGroupStart}-${currentGroupEnd}`;
              } else if (currentGroupEnd) {
                boardingGroup = `${currentGroupEnd}`;
              }
            }

            const paidReturnTime = entity.queue?.PAID_RETURN_TIME;
            const singlePassPrice = paidReturnTime?.price?.amount
              ? paidReturnTime.price.amount / 100 // Assuming amount is in cents
              : null;

            const hasWaitTime = waitTime !== null && waitTime !== undefined;
            const isOperating = status.toLowerCase() === 'operating';

            // Exclude attractions that are 'OPERATING' but have no wait time or boarding group
            if (isOperating && !hasWaitTime && !boardingGroup) {
              return null;
            }

            return {
              id: entity.id,
              name: entity.name,
              waitTime: waitTime,
              status: status,
              returnStart: entity.queue?.RETURN_TIME?.returnStart,
              returnEnd: entity.queue?.RETURN_TIME?.returnEnd,
              returnState: entity.queue?.RETURN_TIME?.state,
              paidReturnStart: paidReturnTime?.returnStart,
              paidReturnEnd: paidReturnTime?.returnEnd,
              paidReturnState: paidReturnTime?.state,
              singlePassPrice: singlePassPrice,
              type: type,
              boardingGroup: boardingGroup,
            };
          })
          .filter((attr) => attr !== null); // Remove null entries

        setAttractions(fetchedAttractions);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching attractions:', error);
        setErrorMessage('Failed to load attractions.');
        setIsLoading(false);
      });
  };

  const fetchTodayOperatingHours = () => {
    setErrorMessage(null);
    apiService
      .fetchParkSchedule(selectedParkId)
      .then((response) => {
        const data = response.data;
        const schedule = data.schedule;

        const today = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format

        // Filter today's schedule entries
        const todaySchedules = schedule.filter((entry) => entry.date === today);

        const filteredHours = todaySchedules.filter(
          (entry) => entry.type !== 'INFO' && entry.openingTime && entry.closingTime
        );

        // Map over filteredHours and set labels based on type and description
        const operatingHours = filteredHours.map((hours) => {
          let label = '';
          if (hours.type === 'TICKETED_EVENT') {
            label = hours.description || 'Ticketed Event';
          } else if (hours.type === 'OPERATING') {
            label = 'Regular Hours';
          } else {
            label = hours.type; // Use the type as label for other types
          }
          return {
            label: label,
            openingTime: hours.openingTime,
            closingTime: hours.closingTime,
          };
        });

        setTodayOperatingHours(operatingHours);
        setParkTimezone(data.timezone); // Set the timezone
      })
      .catch((error) => {
        console.error("Error fetching today's operating hours:", error);
        setErrorMessage("Failed to load today's operating hours.");
      });
  };

  const loadFavorites = () => {
    const savedFavorites = localStorage.getItem('favorites');
    if (savedFavorites) {
      setFavorites(JSON.parse(savedFavorites));
    }
  };

  const saveFavorites = (updatedFavorites) => {
    setFavorites(updatedFavorites);
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));
  };

  const toggleFavorite = (attraction) => {
    const isFav = favorites.some((fav) => fav.id === attraction.id);
    const updatedFavorites = isFav
      ? favorites.filter((fav) => fav.id !== attraction.id)
      : [...favorites, attraction];
    saveFavorites(updatedFavorites);
  };

  const isFavorite = (attraction) => {
    return favorites.some((fav) => fav.id === attraction.id);
  };

  const loadRiddenAttractions = () => {
    const savedData = localStorage.getItem('riddenAttractions');
    const lastReset = localStorage.getItem('lastReset');
    const now = new Date();
    const resetTime = new Date();
    resetTime.setHours(3, 0, 0, 0); // 3am today in local timezone

    if (!lastReset || new Date(lastReset) < resetTime && now >= resetTime) {
      // It's after 3am and last reset was before today
      localStorage.removeItem('riddenAttractions');
      localStorage.setItem('lastReset', now.toISOString());
      setRiddenAttractions([]);
    } else {
      if (savedData) {
        setRiddenAttractions(JSON.parse(savedData));
      }
    }
  };

  const toggleRidden = (attraction) => {
    const isRidden = riddenAttractions.includes(attraction.id);
    let updatedRiddenAttractions;
    if (isRidden) {
      updatedRiddenAttractions = riddenAttractions.filter((id) => id !== attraction.id);
    } else {
      updatedRiddenAttractions = [...riddenAttractions, attraction.id];
    }
    setRiddenAttractions(updatedRiddenAttractions);
    localStorage.setItem('riddenAttractions', JSON.stringify(updatedRiddenAttractions));
  };

  const isRidden = (attraction) => {
    return riddenAttractions.includes(attraction.id);
  };

  // Filter options: 'All', 'Attraction', 'Show', 'Lightning Lane'
  const attractionTypes = ['All', 'Attraction', 'Show', 'Lightning Lane'];

  // Filter and sort attractions based on selected criteria
  const getFilteredAndSortedAttractions = () => {
    let filtered = showFavoritesOnly ? attractions.filter((attr) => isFavorite(attr)) : attractions;

    if (filterType !== 'All') {
      if (filterType === 'Lightning Lane') {
        filtered = filtered.filter((attr) => attr.returnState || attr.paidReturnState);
      } else {
        filtered = filtered.filter((attr) => attr.type.toLowerCase() === filterType.toLowerCase());
      }
    }

    // Adjusted sorting logic
    filtered.sort((a, b) => {
      const getSortValue = (attraction) => {
        if (attraction.boardingGroup !== null && attraction.boardingGroup !== undefined) {
          // Use -1 to place attractions with boarding groups at the top
          return -1;
        }
        if (attraction.waitTime !== null && attraction.waitTime !== undefined) {
          return attraction.waitTime;
        }
        return Number.MAX_SAFE_INTEGER; // Treat as very high wait time if neither is available
      };

      const valueA = getSortValue(a);
      const valueB = getSortValue(b);

      if (valueA === valueB) return 0;

      if (sortOrder === 'asc') {
        return valueA - valueB;
      } else {
        return valueB - valueA;
      }
    });

    return filtered;
  };

  const filteredAttractions = getFilteredAndSortedAttractions();

  const formattedTime = (timeString, timeZone) => {
    if (!timeString) return 'Closed';
    const date = new Date(timeString);
    return date.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', timeZone: timeZone });
  };

  // Compute counts for Rode attractions
  const currentParkRodeCount = attractions.filter((attr) => riddenAttractions.includes(attr.id)).length;
  const totalRodeCount = riddenAttractions.length;
  const totalAttractionsInCurrentPark = attractions.length;

  return (
    <div className="app-container">
      <header className="app-header">
        <h1>WDW Wait Times</h1>
        <div className="top-controls">
          <button className="refresh-button" onClick={fetchAttractions}>
            🔄
          </button>
          <label className="toggle-switch">
            <input
              type="checkbox"
              checked={darkMode}
              onChange={() => setDarkMode(!darkMode)}
            />
            <span className="slider"></span>
            <span className="toggle-label">{darkMode ? 'Dark Mode' : 'Light Mode'}</span>
          </label>
        </div>
      </header>
      {/* Attraction Counter */}
      <div className="attraction-counter">
        <p>
          Rode {currentParkRodeCount} of {totalAttractionsInCurrentPark} attractions in this park.
        </p>
        <p>Total attractions ridden across all parks: {totalRodeCount}</p>
      </div>
      <div className="park-selector">
        {parkOptions.map((park) => (
          <button
            key={park.id}
            className={`park-button ${selectedParkId === park.id ? 'selected' : ''}`}
            onClick={() => setSelectedParkId(park.id)}
          >
            {park.name}
          </button>
        ))}
      </div>
      {todayOperatingHours.length > 0 && (
        <div className="today-hours">
          <h3>Today's Hours:</h3>
          {todayOperatingHours.map((hours, index) => (
            <p key={index}>
              <strong>{hours.label}:</strong> {formattedTime(hours.openingTime, parkTimezone)} -{' '}
              {formattedTime(hours.closingTime, parkTimezone)}
            </p>
          ))}
        </div>
      )}
      <div className="controls">
        <div className="favorites-toggle">
          <label>
            <input
              type="checkbox"
              checked={showFavoritesOnly}
              onChange={() => setShowFavoritesOnly(!showFavoritesOnly)}
            />
            Show Favorites Only
          </label>
        </div>
        <div className="filter-sort">
          <label>
            Filter:
            <select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
              {attractionTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>
          <label>
            Sort by Wait Time:
            <select value={sortOrder} onChange={(e) => setSortOrder(e.target.value)}>
              <option value="asc">Ascending</option>
              <option value="desc">Descending</option>
            </select>
          </label>
        </div>
      </div>
      {isLoading && <div className="loading">Loading...</div>}
      {errorMessage && <div className="error-message">{errorMessage}</div>}
      <div className="attractions-list">
        {filteredAttractions.map((attraction) => (
          <AttractionRow
            key={attraction.id}
            attraction={attraction}
            isFavorite={isFavorite(attraction)}
            toggleFavorite={toggleFavorite}
            isRidden={isRidden(attraction)}
            toggleRidden={toggleRidden}
          />
        ))}
      </div>
      <footer className="app-footer">
        <p>
          Wait time data courtesy of{' '}
          <a href="https://themeparks.wiki" target="_blank" rel="noopener noreferrer">
            ThemeParks.wiki
          </a>
        </p>
      </footer>
    </div>
  );
}

export default App;