// src/apiService.js

import axios from 'axios';

const API_BASE_URL = 'https://api.themeparks.wiki/v1';

const apiService = {
  fetchEntityLiveData: (entityID) => {
    return axios.get(`${API_BASE_URL}/entity/${entityID}/live`);
  },
  fetchParkSchedule: (entityID) => {
    return axios.get(`${API_BASE_URL}/entity/${entityID}/schedule`);
  },
};

export default apiService;